<template>
  <div class="auth-wrapper auth-v1 pa-0 white">
    <div class="auth-inner">
      <v-card class="auth-card round-16" elevation="0">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <v-avatar
            color="primary"
            size="120"
            class="pa-3"
            >
            <v-img
              :src="require('@/assets/images/logos/logo-primary-white.png')"
              width="100px"
              alt="logo"
              contain
            ></v-img>
          </v-avatar>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2 text-center">
            Welcome to VDC Platform
          </p>
          <!-- <p class="mb-2">
            Login to Manage Campaign 
          </p> -->
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="email"
              outlined
              rounded
              label="Email"
              placeholder="vdc@example.com"
              hide-details
              class="mb-3"
              :rules="emailRules"
              required
              @keyup.enter="validate"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              rounded
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="[v => !!v || 'Password belum diisi']"
              required
              @keyup.enter="validate"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <!-- <v-checkbox
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox> -->

              <!-- forgot link -->
              <!-- <a
                href="javascript:void(0)"
                class="mt-1"
              >
                Forgot Password?
              </a> -->
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6 mt-10 text-capitalize"
              @click="validate"
              :disabled="isLoading"
              rounded
              x-large
            >
              Login
            </v-btn>
            <p class="mt-3 text-center">New here?
              <v-tooltip bottom color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <span @click.prevent="goToRegister" v-bind="attrs" v-on="on" class="primary--text font-weight-medium text-decoration-underline" style="cursor:pointer"
                  >
                  Register
                  </span>
                </template>
                <span>Register Now</span>
              </v-tooltip>
          </p>
          </v-form>
          <div class="d-flex justify-center mt-5">
            <v-progress-circular
              v-if="isLoading"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-alert v-if="alertStatus" :type="alertType" class="full-width">
              {{alertMessage}} <button style="float:right" type="button" v-on:click="closeAlert" class="close right" data-dismiss="alert-success" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </v-alert>
          </div>
        </v-card-text>

        <!-- create new account  -->
        <!-- <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            New on our platform?
          </span>
          <router-link :to="{name:'pages-register'}">
            Create an account
          </router-link>
        </v-card-text> -->

        <!-- divider -->
        <!-- <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text> -->

        <!-- social links -->
        <v-card-actions class="d-flex justify-center">
          <!-- <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import axios from 'axios'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,
      valid:true,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      isLoading:false,
      alertStatus:false,
      alertType:'error',
      alertMessage:'Loading . . .',

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  mounted(){
    // let user = {
    //   'id': 1,
    //   'name': 'NAMA'
    // }
    // localStorage.setItem('user', user)
    // if(localStorage.getItem('token')){
    //   console.log('ADA', localStorage.getItem('token'))
    // } else {
    //   console.log('TIDAK ADA')
    // }
  },
  methods:{
    validate () {
      let checkValid = this.$refs.form.validate()
      if(checkValid){
        this.submit();
      }
    },
    submit () {
        this.isLoading = true;
        // if(localStorage.getItem('user') != null){
        //   this.user = localStorage.getItem('user');
        // }
        axios.post(
            process.env.VUE_APP_API_ENDPOINT + 'users/login', { pin: this.password, email: this.email}
          ).then(response => {
            if(response){
              localStorage.setItem('token', response.data.data.token)
              setTimeout(() => { 
                this.alertType='success';
                this.alertMessage=response.data.message;
                this.$router.push({name:'dashboard'});
                this.isLoading = false; 
                this.alertStatus=true;
                this.resetForm();
              }, 2000);
            }
          }).catch(error => {
            setTimeout(() => { 
              this.isLoading = false; 
              this.alertType='error';
              this.alertMessage='Email / Password Salah';
              this.alertStatus=true;
              this.resetForm();
            }, 2000);
        });
    },
    goToRegister(){
      window.open('https://vicedigitalcreative.com/for_brands/get-started');
    },
    closeAlert(){
      this.alertStatus = false;
    },
    resetForm(){
      this.password = this.email = '';
    }
  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
